import React from "react"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"

export default () => (
  <Layout>
    <div className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-12"}>
          <div className={"row"}>
            <StyledHeadlineWrapper>
              <Headline
                headlineH1="Kontaktformular"
                thinSubline="Danke für deine Nachricht. Wir melden uns"
              />
            </StyledHeadlineWrapper>
          </div>
        </div>
      </div>
    </div>
</Layout>
)
